






















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import PaymentModel from '@/models/Payment.model';
import ModalRefundPayment from '@/components/Payments/ModalRefundPayment.vue';
import PaymentsRepository from '@/repository/modules/payments.repository';
import InvoicesRepository from '@/repository/modules/invoices.repository';

import { setterDate, setterAmount } from '@/plugins/utils';

@Component({
  components: {
    ModalRefundPayment,
  },
})
export default class ListPaymentsCustomer extends Vue {
  @Prop({ required: true, type: String })
  public user_id!: string;
  private payments: PaymentModel[] = [];
  private page = 1;
  private limit = 100;
  private total_results = 0;
  private loading = true;

  async mounted(): Promise<void> {
    setTimeout(async () => {
      await this.getPayments(this.page);
    }, 1000);
  }

  async getPayments(page: number): Promise<void> {
    this.loading = true;
    const { payments, total } = await PaymentsRepository.getListPayments({
      query: { user_id: this.user_id },
      page,
      limit: this.limit,
    });
    this.payments = payments;
    console.log(this.payments);
    this.total_results = total ?? 0;
    this.loading = false;
  }

  setDate = (dater: Date) => setterDate(dater);
  setAmount = (amount: number) => setterAmount(amount);

  async downloadInvoiceByID(invoice_id: string, id_num: string) {
    await InvoicesRepository.downloadInvoice(invoice_id, id_num);
  }

  showAlertRefund = false;
  isLoadingRefund = false;
  payment_id!: string | null;

  openAlertRefund(payment_id: string) {
    this.payment_id = payment_id;
    this.showAlertRefund = true;
  }

  closeAlertRefund() {
    this.payment_id = null;
    this.showAlertRefund = false;
  }

  async refundPayment() {
    this.isLoadingRefund = true;
    if (this.payment_id) await PaymentsRepository.refundPayment(this.payment_id);
    this.isLoadingRefund = false;
    this.showAlertRefund = false;
    this.$emit('RELOAD_CUSTOMER');
  }
}
