export interface CustomerProfilePaymentsModelImpl {
  _id: string;
  user_id: string;
  product_id: string;
  provider: string;
  provider_token: string;
  provider_customer: string;
  cancelled_type: string;
  next_payment_at: Date | null;
  cancelled_at: Date | null;
  premium_ends_at: Date | null;
  updated_at: Date | null;
}

export class CustomerProfilePaymentsModelDTO implements CustomerProfilePaymentsModelImpl {
  _id = '';
  user_id = '';
  product_id = '';
  provider = '';
  provider_token = '';
  provider_customer = '';
  next_payment_at = null;
  cancelled_type = '';
  cancelled_at = null;
  premium_ends_at = null;
  updated_at = null;
}

export default class CustomerProfilePaymentsModel extends CustomerProfilePaymentsModelDTO {
  constructor(dto: CustomerProfilePaymentsModelDTO) {
    super();
    Object.assign(this, dto);
  }

  getExternalLinkCustomer() {
    if (this.provider == 'stripe') return `https://dashboard.stripe.com/customers/${this.provider_customer}`;
    else return '';
  }

  isSubscriptor() {
    if (this.next_payment_at) return true;
    else false;
  }

  HasPremiumAccess() {
    if (this.next_payment_at) return true;
    else if (
      !this.next_payment_at &&
      this.premium_ends_at &&
      new Date((this.premium_ends_at as unknown) as Date) > new Date()
    )
      return true;
    else return false;
  }
}
