










































import { EansOrdersModel } from '@/models/OrderEans.model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalCreateOrder from './ModalCreateOrder.vue';

@Component({
  components: {
    ModalCreateOrder,
  },
})
export default class DetailsOrders extends Vue {
  @Prop({ required: true, type: String })
  user_id!: string;
  @Prop({
    required: true,
    validator: (prop) => Array.isArray(prop) && prop.every((item) => item instanceof EansOrdersModel),
  })
  customerOrders!: EansOrdersModel[];

  showCreateOrder = false;

  created() {
    console.log(this.customerOrders);
  }

  //async createOrder() {}
}
