import API from '../repository';
import CustomerModel, { CustomerModelDTO } from '@/models/Customer.model';
import CustomerProfileBillingModel, { CustomerProfileBillingModelDTO } from '@/models/CustomerProfileBilling.model';
import CustomerProfilePaymentsModel, { CustomerProfilePaymentsModelDTO } from '@/models/CustomerProfilePayments.model';
import { EansOrdersDTO, EansOrdersModel } from '@/models/OrderEans.model';

const RESOURCE = '/customers';

type updateProfileBilling = {
  company_name: string;
  company_vat: string;
  company_address: string;
  company_city: string;
  company_country: string;
};

type createCustomOrder = {
  cuantity: number;
  price: number;
};

export default {
  async getListCustomers({ query = {}, page = 0, limit = 10 }): Promise<{ customers: CustomerModel[]; total: number }> {
    const { listUsers, total } = await API.post(`${RESOURCE}`, {
      page,
      limit,
      query,
    });
    const customersModels = listUsers.map((customerDto: CustomerModelDTO) => new CustomerModel(customerDto));
    return {
      customers: customersModels,
      total: total,
    };
  },

  async getFullDataUser({ user_id }: { user_id: string }) {
    const {
      user,
      userProfileBilling,
      userProfilePayments,
      userOrders,
    }: {
      user: CustomerModelDTO;
      userProfileBilling: CustomerProfileBillingModelDTO;
      userProfilePayments: CustomerProfilePaymentsModelDTO;
      userOrders: EansOrdersDTO[];
    } = await API.get(`${RESOURCE}/${user_id}`);
    return {
      customer: new CustomerModel(user),
      profileBilling: new CustomerProfileBillingModel(userProfileBilling),
      profilePayments: new CustomerProfilePaymentsModel(userProfilePayments),
      customerOrders: userOrders.map(o => new EansOrdersModel(o)),
    };
  },

  async unSubscribeUser({
    user_id,
    profile_payments_id,
    unsubscribe_method,
  }: {
    user_id: string;
    profile_payments_id: string;
    unsubscribe_method: string;
  }): Promise<boolean> {
    return await API.put(`${RESOURCE}/${user_id}/unsubscribe`, {
      profile_payments_id,
      unsubscribe_method,
    });
  },

  async updateProfileBilling(user_id: string, updateData: updateProfileBilling): Promise<boolean> {
    return await API.put(`${RESOURCE}/${user_id}/profile-billing`, {
      ...updateData,
    });
  },

  async createCustomOrder(user_id: string, updateData: createCustomOrder) {
    return await API.post(`${RESOURCE}/${user_id}/order`, {
      order_cuantity: updateData.cuantity,
      order_price: updateData.price,
    });
  },
};
