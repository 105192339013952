
















import ListPaymentsCustomer from '@/components/CustomerProfile/ListPaymentsCustomer.vue';
import DetailsCustomer from '@/components/CustomerProfile/DetailsCustomer.vue';
import DetailsOrders from '@/components/CustomerProfile/DetailsOrders.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomersRepository from '@/repository/modules/customers.repository';
import CustomerModel from '@/models/Customer.model';
import CustomerProfileBillingModel from '@/models/CustomerProfileBilling.model';
import CustomerProfilePaymentsModel from '@/models/CustomerProfilePayments.model';
import { EansOrdersModel } from '@/models/OrderEans.model';

@Component({
  components: {
    ListPaymentsCustomer,
    DetailsCustomer,
    DetailsOrders,
  },
  props: {
    customer_id: {
      type: String,
      required: true,
    },
  },
})
export default class CustomerProfile extends Vue {
  @Prop({ required: true, type: String })
  customer_id!: string;
  customer!: CustomerModel;
  profileBilling!: CustomerProfileBillingModel;
  profilePayments!: CustomerProfilePaymentsModel;
  customerOrders!: EansOrdersModel[];
  loading = true;

  async mounted(): Promise<void> {
    await this.getCustomer();
  }

  async getCustomer() {
    this.loading = true;
    const { customer, profileBilling, profilePayments, customerOrders } = await CustomersRepository.getFullDataUser({
      user_id: this.customer_id,
    });
    this.loading = false;
    this.customer = customer;
    this.profileBilling = profileBilling;
    this.profilePayments = profilePayments;
    this.customerOrders = customerOrders;
  }
}
