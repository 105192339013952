export interface CustomerProfileBillingModelImpl {
  _id: string;
  user_uid: string;
  vat_is_valid: boolean;
  company_address: string;
  company_city: string;
  company_country: string;
  company_name: string;
  company_vat: string;
}

export class CustomerProfileBillingModelDTO implements CustomerProfileBillingModelImpl {
  _id = '';
  user_uid = '';
  vat_is_valid = false;
  company_address = '';
  company_city = '';
  company_country = '';
  company_name = '';
  company_vat = '';
}

export default class CustomerProfileBillingModel extends CustomerProfileBillingModelDTO {
  constructor(dto: CustomerProfileBillingModelDTO) {
    super();
    Object.assign(this, dto);
  }
}
