
















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomerModel from '@/models/Customer.model';
import { setterDate, setterAmount } from '@/plugins/utils';
import CustomerProfileBillingModel from '@/models/CustomerProfileBilling.model';

import CustomerRepository from '@/repository/modules/customers.repository';

@Component
export default class DetailsCustomer extends Vue {
  @Prop({ required: true, type: CustomerModel })
  customer!: CustomerModel;
  @Prop({ required: true, type: CustomerProfileBillingModel })
  customerProfileBilling!: CustomerProfileBillingModel;

  showEditorProfileBilling = false;
  isLoading = false;

  form = {
    company_vat: '',
    company_name: '',
    company_address: '',
    company_city: '',
    company_country: '',
  };

  openDialogFormProfileBilling() {
    this.setValueFormProfileBilling();
    this.showEditorProfileBilling = true;
  }

  setValueFormProfileBilling() {
    this.form.company_vat = this.customerProfileBilling.company_vat;
    this.form.company_name = this.customerProfileBilling.company_name;
    this.form.company_address = this.customerProfileBilling.company_address;
    this.form.company_city = this.customerProfileBilling.company_city;
    this.form.company_country = this.customerProfileBilling.company_country;
  }

  async updateProfileBilling() {
    this.isLoading = true;
    CustomerRepository.updateProfileBilling(this.customer._id, {
      company_name: this.form.company_name,
      company_vat: this.form.company_vat,
      company_address: this.form.company_address,
      company_city: this.form.company_city,
      company_country: this.form.company_country,
    })
      .then((result) => {
        this.isLoading = false;
        this.showEditorProfileBilling = false;
        this.$emit('RELOAD_CUSTOMER');
      })
      .catch((e) => {
        this.isLoading = false;
        console.log(e);
      });
  }

  setDate = (dater: Date) => setterDate(dater);
  setEmptyField = (field: string) => (field == '' ? '--' : field);
}
