var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"mb-5"},[_c('div',{staticClass:"d-flex flex-row justify-space-between pb-4"},[_c('h1',{staticClass:"text-h6"},[_vm._v("Orders")]),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.showCreateOrder = true}}},[_vm._v("Add new")])],1),_c('v-divider'),_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.customerOrders == undefined}}),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-uppercase"},[_vm._v("Date")]),_c('th',{staticClass:"text-uppercase"},[_vm._v("Quantity")]),_c('th',{staticClass:"text-uppercase"},[_vm._v("Description")])])]),_c('tbody',_vm._l((_vm.customerOrders),function(order){return _c('tr',{key:order._id},[_c('td',[(order.status === 3)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({attrs:{"color":"success","small":""}},on),[_vm._v("mdi-check-circle")])]}}],null,true)},[_c('span',[_vm._v("Success")])]):_vm._e(),(order.status === 2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({attrs:{"color":"amber","small":""}},on),[_vm._v("mdi-check-circle")])]}}],null,true)},[_c('span',[_vm._v("In process to send")])]):_vm._e(),(order.status === 1 || order.status === null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({attrs:{"color":"error","small":""}},on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v("Not payed")])]):_vm._e()],1),_c('td',{domProps:{"innerHTML":_vm._s(order.getOrderDate())}}),_c('td',{staticClass:"caption"},[_vm._v(_vm._s(order.total_eans))]),_c('td',{staticClass:"caption"},[_vm._v(_vm._s(order.description))])])}),0)]},proxy:true}])})],1)],1),_c('ModalCreateOrder',{attrs:{"showCreateOrder":_vm.showCreateOrder,"user_id":_vm.user_id},on:{"BACK":function($event){_vm.showCreateOrder = false},"RELOAD_CUSTOMER":function($event){return _vm.$emit("RELOAD_CUSTOMER")}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }