import { setterAmount, setterDate } from '@/plugins/utils';

export interface EansOrdersImpl {
  _id: string | null;
  description: string;
  status: number;
  total_eans: number;
  total_price: number;
  created_at: Date | null;
}

export class EansOrdersDTO implements EansOrdersImpl {
  _id = null;
  description = '';
  status = 0;
  total_eans = 0;
  total_price = 0;
  created_at!: Date;
}

export class EansOrdersModel extends EansOrdersDTO {
  constructor(dto: EansOrdersDTO) {
    super();
    Object.assign(this, dto);
  }

  getOrderDate = () => setterDate(this.created_at);

  isPayed = () => (this.status === 3 ? true : false);
}
