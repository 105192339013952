
































import { Component, Prop, Vue } from 'vue-property-decorator';

import CustomerRepository from '@/repository/modules/customers.repository';

@Component({})
export default class ModalCreateOrder extends Vue {
  @Prop({ required: true, type: String })
  user_id!: string;

  @Prop({ required: true, type: Boolean })
  showCreateOrder = false;

  isLoading = false;
  form = {
    order_price: 0,
    order_cuantity: 0,
  };

  get IsValidForm() {
    if (this.form.order_price < 15000) return false;
    if (this.form.order_cuantity < 1000) return false;
    return true;
  }

  setterPriceHint(price: number) {
    if (price == 0) return '1.00';
    return (price / 100).toFixed(2);
  }

  async createOrder() {
    this.isLoading = true;
    const cuantity = this.forceNumberType(this.form.order_cuantity);
    const price = this.forceNumberType(this.form.order_price);
    await CustomerRepository.createCustomOrder(this.user_id, {
      cuantity,
      price,
    });
    this.isLoading = false;
    this.$emit('RELOAD_CUSTOMER');
  }

  forceNumberType = (n: number): number => parseInt((n as unknown) as string);
}
